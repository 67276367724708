import ZjSvg from '~/assets/svgs/zhangjie.svg';
import SjSvg from '~/assets/svgs/shijuan.svg';
import CtSvg from '~/assets/svgs/cuoti.svg';
import ScSvg from '~/assets/svgs/shoucang.svg';

import user from '~/assets/images/user/menu/user.png';
import mysearch from '~/assets/images/user/menu/mysearch.png';
import mytiku from '~/assets/images/user/menu/mytiku.png';
import myclass from '~/assets/images/user/menu/myclass.png';
import coupon from '~/assets/images/user/menu/coupon.png';
import myorder from '~/assets/images/user/menu/myorder.png';
import set from '~/assets/images/user/menu/set.png';
import loginout from '~/assets/images/user/menu/loginout.png';

import user_active from '~/assets/images/user/menu/user-active.png';
import mysearch_active from '~/assets/images/user/menu/mysearch-active.png';
import mytiku_active from '~/assets/images/user/menu/mytiku-active.png';
import myclass_active from '~/assets/images/user/menu/myclass-active.png';
import coupon_active from '~/assets/images/user/menu/coupon-active.png';
import myorder_active from '~/assets/images/user/menu/myorder-active.png';
import set_active from '~/assets/images/user/menu/set-active.png';
import loginout_active from '~/assets/images/user/menu/loginout-active.png';

export const userMenu = [
  { title: '个人中心', path: '/user', icon: user, active_icon: user_active },
  { title: '我的搜题', path: '/user/packet', icon: mysearch, active_icon: mysearch_active },
  { title: '我的题库', path: '/user/tiku', icon: mytiku, active_icon: mytiku_active },
  { title: '我的课程', path: '/user/course', icon: myclass, active_icon: myclass_active },
  { title: '我的优惠券', path: '/user/coupon', icon: coupon, active_icon: coupon_active },
  { title: '我的订单', path: '/user/order', icon: myorder, active_icon: myorder_active },
  { title: '账号设置', path: '/user/account', icon: set, active_icon: set_active },
  { title: '退出登录', path: '', icon: loginout, active_icon: loginout_active }
]
// exam导航菜单
export const examMenu = [
  {
    title: '章节练习',
    name: 'practice',
    path: '/home/practice',
    icon: ZjSvg
  },
  {
    title: '试卷列表',
    name: 'mockRoom',
    path: '/home/mockRoom',
    icon: SjSvg
  },
  {
      title: '错题强化',
      name: 'wrongStrengthen',
      path: '/home/wrongIntensify',
      icon: CtSvg
  },
  {
      title: '收藏试题',
      name: 'collect',
      path: '/home/collect',
      icon: ScSvg
  },
];
// 搜索结果列表tabs 菜单
export const searchTabs = ['综合', '试题', '题库', '网课'];
// 路径类型
export const topicPath = ['/shiti', '/tiku/shiti', '/kaoti'];
//首页路径导航配置
export const mobileHomeNavs = [
  { text: '首页', url: '/' },
  { text: '题库', url: '/tiku' },
  { text: '网课', url: '/wangke' },
  { text: '在线模考', url: '/mnkc'},
];
// 用户信息导航
export const userMobileToolBar = [
   {title: '个人中心', icon: 'icon-user.png', url: 'https://h5.ppkao.com/#/me'},
   {title: '购买次数', icon: 'icon-vip.png',  payment: true},
   {title: '账号设置', icon: 'icon-setting.png', url: 'https://h5.ppkao.com/#/accountSetting'},
   {title: '退出登录', icon: 'icon-logout.png', exit: true}
 ];
// 移动端配置
export const userToolBar = [
  {title: '个人中心', icon: 'icon-user.png', url: '/user'},
  {title: '购买次数', icon: 'icon-vip.png',  payment: true},
  {title: '账号设置', icon: 'icon-setting.png', url: '/user/account'},
  {title: '退出登录', icon: 'icon-logout.png', exit: true}
];
// nav 菜单
export const homeNavs = [
  { text: '首页', url: '/' },
  { text: '题库', url: '/tiku' },
  { text: '网课', url: '/wangke' },
  { text: '在线模考', url: '/mnkc'},
  { text: '桌面端', url: '/download', isDesktop: true},
  // { img: 'https://static.ppkao.com/www/images/deepseek.webp', url: '/ai', external: true }
];

